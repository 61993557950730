import { createRouter, createWebHistory } from 'vue-router'
import { projectAuth } from '../firebase'

const requireAuth = (to, from, next) => {
	let user = projectAuth.currentUser
	if (!user) {
		next({ name: 'Home' })
	}

	if(user.emailVerified === false) {
		next({ name: 'Verify' })
	} else {
		next()
	}
}

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/HomeView.vue')
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/RegisterView.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/LoginView.vue')
	},
	{
		path: '/forgot',
		name: 'Forgot',
		component: () => import('../views/ForgotView.vue')
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('../views/LogoutView.vue')
	},
	{
		path: '/verify',
		name: 'Verify',
		component: () => import('../views/VerifyView.vue')
	},
	{
		path: '/loading',
		name: 'Loading',
		component: () => import('../views/LoadingView.vue')
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../views/DashboardView.vue'),
		beforeEnter: requireAuth,
		children: [
			{
				path: '',
				name: 'Dashboard Index',
				component: () => import('../views/DashboardIndex.vue'),
				meta: {
					routeClass: 'dashboard-index'
				}
			},
			{
				path: 'profile',
				name: 'Dashboard Profile',
				component: () => import('../views/DashboardProfile.vue'),
				meta: {
					routeClass: 'dashboard-profile'
				}
			},
			{
				path: 'boxes',
				name: 'Dashboard Boxes',
				component: () => import('../views/DashboardBoxes.vue'),
				children: [
					{
						path: '',
						name: 'Dashboard Boxes Index',
						component: () => import('../views/DashboardBoxesIndex.vue') 
					},
					{
						path: 'view/:id',
						name: 'Dashboard Boxes View',
						component: () => import('../views/DashboardBoxesView.vue') 
					}
				]
			},
			{
				path: 'admin',
				name: 'Dashboard Admin',
				component: () => import('../views/DashboardAdmin.vue'),
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
