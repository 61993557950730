import { createStore } from 'vuex'
import { customAlphabet } from 'nanoid'
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_-';
const nanoid = customAlphabet(alphabet, 15);

import { projectFirestore,projectAuth,timestamp,field_value} from '../firebase'
const axios = require('axios').default

export default createStore({
	state: {
		user: null,
		users: [],
		games: [],
		gamesSnap: null,
		pools: [],
		poolsSnap: null,
		boxes: [],
		boxesSnap: null
	},
	getters: {
		loggedInUser: state => {
			return state.user
		},
		gamesSingle: state => {
			return state.games[0]
		},
		userPools: state => {
			return state.pools
		},
		singlePool: state => id => {
			return state.pools.find(pool => pool.id === id)
		},
		poolBoxes: state => id => {
			return state.boxes.filter(box => box.poolID === id)
		}
	},
	mutations: {
		defineUser(state,payload){
			state.user = payload
		},
		setGames(state,payload){
			state.games = payload
		},
			addGamesSnap(state,payload){
				state.gamesSnap = payload
			},
		setPools(state,payload){
			state.pools = payload
		},
			addPoolsSnap(state,payload){
				state.poolsSnap = payload
			},
		setBoxes(state,payload){
			state.boxes = payload
		},
			addBoxesSnap(state,payload){
				state.boxesSnap = payload
			}
	},
	actions: {
		async addUserGmail(context,payload){
			function extractUsername(email) {
				const atIndex = email.indexOf('@');
				if (atIndex > 0) {
					return email.substring(0, Math.min(atIndex, 6));
				} else {
					return '';
				}
			}

			let userData = {
				email: payload.user.email.toLowerCase(),
				uid: payload.user.uid,
				altid: 'US|' + nanoid(),
				dateCreated: timestamp(),
				role: 0,
				nameFirst: null,
				nameLast: null,
				username: 'usr' + Math.floor(Math.random() * 100000),
				nickname: extractUsername(payload.user.email.toLowerCase()),
				terms: true,
				marketing: false
			}

			try {
				return projectFirestore.collection("users")
					.where("email", "==", userData.email)
					.get()
					.then(querySnapshot => {
						if (querySnapshot.empty) { // create profile
							return projectFirestore.collection('users').add(userData).then(() => {

								if(payload.code){
									return projectFirestore.collection("pools")
									.where("code", "==", payload.code)
									.get()
									.then(querySnapshot => {
										if (!querySnapshot.empty) {
											const doc = querySnapshot.docs[0]
											const poolData = doc.data()

											if (!poolData.players.includes(userData.email)) {
													return doc.ref.update({
														players: field_value.arrayUnion(userData.email)
													})
													.then(() => {
														var returnObj = {
															'msg': 'success',
															'code': null
														}
														console.log("Email added!");
														return returnObj
													})
													.catch(error => {
														console.error("Error updating document: ", error);
													});
											} else {
												console.log("Email already exists");
											}
										} else {
											console.log("No document found");
										}
									})
									.catch(error => {
										console.error("Error getting documents: ", error);
									});
								} else {
									var returnObj = {
										'msg': 'success',
										'code': null
									}

									return returnObj
								}


							})
						} else {


								if(payload.code){
									return projectFirestore.collection("pools")
									.where("code", "==", payload.code)
									.get()
									.then(querySnapshot => {
										if (!querySnapshot.empty) {
											const doc = querySnapshot.docs[0]
											const poolData = doc.data()

											if (!poolData.players.includes(userData.email)) {
													return doc.ref.update({
														players: field_value.arrayUnion(userData.email)
													})
													.then(() => {
														var returnObj = {
															'msg': 'success',
															'code': null
														}
														console.log("Email added to pool successfully!");
														return returnObj
													})
													.catch(error => {
														console.error("Error updating document: ", error);
													});
											} else {
												console.log("Email already exists in the pool");
											}
										} else {
											console.log("No document found with the given code");
										}
									})
									.catch(error => {
										console.error("Error getting documents: ", error);
									});
								} else {
									var returnObj = {
										'msg': 'success',
										'code': null
									}

									return returnObj
								}



						}
					})
			}
			catch(error){
				var returnObj = {
					'msg': error.message,
					'code': error.code
				}

				return returnObj
			}



		},
		async addUser(context,payload){

			function extractUsername(email) {
				const atIndex = email.indexOf('@');
				if (atIndex > 0) {
					return email.substring(0, Math.min(atIndex, 6));
				} else {
					return '';
				}
			}

			payload.dateCreated = timestamp()
			payload.nickname = extractUsername(payload.email.toLowerCase())

			try {
				return await projectAuth.createUserWithEmailAndPassword(payload.email,payload.password)
				.then((userCredential) => {
					var user = userCredential.user
					payload.uid = user.uid

					delete(payload.password)

					return projectFirestore.collection('users').add(payload).then(() => {
						user.sendEmailVerification({
							url: 'https://boxeasy.wtf/loading'
						})

						if(payload.code == null){
							console.log('no code')

							var returnObj = {
								'msg': 'success',
								'code': null
							}

							return returnObj
						} else {



							return projectFirestore.collection("pools")
							.where("code", "==", payload.code)
							.get()
							.then(querySnapshot => {
								if (!querySnapshot.empty) {
									const doc = querySnapshot.docs[0]
									const poolData = doc.data()

									if (!poolData.players.includes(payload.email)) {
											return doc.ref.update({
												players: field_value.arrayUnion(payload.email)
											})
											.then(() => {
												var returnObj = {
													'msg': 'success',
													'code': null
												}
												console.log("Email added to pool successfully!");
												return returnObj
											})
											.catch(error => {
												console.error("Error updating document: ", error);
											});
									} else {
										console.log("Email already exists in the pool");
									}
								} else {
									console.log("No document found with the given code");
								}
							})
							.catch(error => {
								console.error("Error getting documents: ", error);
							});



						}

					})
				})
			} 
			catch(error){
				var returnObj = {
					'msg': error.message,
					'code': error.code
				}

				return returnObj
			}
		},
		async sendVerification(){
			return new Promise((resolve, reject) => {

				try {
					let getUser = projectAuth.currentUser
						getUser.sendEmailVerification({
							url: 'https://boxeasy.wtf/loading'
						})

					var returnObj = {
						'msg': 'success',
						'code': null
					}
					resolve(returnObj)
				} 
				catch(error){
					returnObj = {
						'msg': error.message,
						'code': error.code
					}

					reject(returnObj)
				}
				finally {
					//context.commit('addClient',payload)
				}
			})
		},
		async userLogin(context,payload){ 
			return await projectAuth.signInWithEmailAndPassword(payload.email,payload.password).then(() => {



				if(payload.code != null){

					return projectFirestore.collection("pools")
					.where("code", "==", payload.code)
					.get()
					.then(querySnapshot => {
						if (!querySnapshot.empty) {
							const doc = querySnapshot.docs[0]
							const poolData = doc.data()

							if (!poolData.players.includes(payload.email)) {
									return doc.ref.update({
										players: field_value.arrayUnion(payload.email)
									})
									.then(() => {
										var returnObj = {
											'msg': 'success',
											'code': null
										}
										console.log("Email added to pool successfully!");
										return returnObj
									})
									.catch(error => {
										console.error("Error updating document: ", error);
									});
							} else {
								console.log("Email already exists in the pool");
							}
						} else {
							console.log("No document found with the given code");
						}
					})
					.catch(error => {
						console.error("Error getting documents: ", error);
					});

				} else {
					var returnObj = {
						'msg': 'success',
						'code': null
					}

					return returnObj
				}
			})
			.catch((error) => {
				var returnObj = {
					'msg': error.message,
					'code': error.code
				}

				return returnObj;
			});
		},
		async resetPassword(context,payload){
			return await projectAuth.sendPasswordResetEmail(payload.email,{
				url: 'https://boxeasy.wtf/loading'
			})
			.then(() => {
				var returnObj = {
					'msg': 'success',
					'code': null
				}

				return returnObj
			})
			.catch((error) => {
				var returnObj = {
					'msg': error.message,
					'code': error.code
				}

				return returnObj;
			});

		},
		async logOut(){ // context
			var unsubscribe_arr = []

			if(this.state.gamesSnap !== null){
				unsubscribe_arr.push(this.state.gamesSnap())
			}

			if(this.state.poolsSnap !== null){
				unsubscribe_arr.push(this.state.poolsSnap())
			}

			if(this.state.boxesSnap !== null){
				unsubscribe_arr.push(this.state.boxesSnap())
			}

			Promise.all(unsubscribe_arr).then(() => {
				projectAuth.signOut().then(() => {
				}).catch((error) => {
					alert(error)
				})
			});
		},
		async loadAuth(context){
			let userProfile = null
			try {
				await projectAuth.onAuthStateChanged((user) => {


					console.log('in load auth...')

					console.log(user);


					if (user) {
						projectFirestore.collection('users')
							.where("uid","==",user.uid)
							.get()
							.then((profile) => {
								userProfile = {
									uid: user.uid,
									email: user.email,
									nickname: profile.docs[0].data().nickname,
									role: profile.docs[0].data().role,
									profileId: profile.docs[0].id
								}
								context.commit('defineUser',userProfile)
							})
					} else {
						context.commit('defineUser',userProfile)
					}
				});
			}
			catch(err){
				alert(err)
			}
		},
		async loadGames(context){ // payload
			try {
				const unsubscribe = await projectFirestore.collection('games')
				.where("altid","==","GA|4T5J42wSTsXy4F4") // need to replace for more/future games
				.onSnapshot(snap => {
					let docs = snap.docs.map(doc => {
						return { ...doc.data(), id: doc.id }
					})
					context.commit('setGames',docs)
					context.commit('addGamesSnap',unsubscribe)
				})
			}
			catch(err){
				alert(err)
			}
		},
		async addPool(context,payload){
			return new Promise((resolve, reject) => {
				payload.dateCreated = timestamp()

				try {
					projectFirestore.collection("pools").add(payload).then((document) =>{

						var returnObj = {
							'msg': 'success',
							'doc': document,
							'code': null
						}

						resolve(returnObj)
					})
				}
				catch(error){
					var returnObj = {
						'msg': error.message,
						'code': error.code
					}

					reject(returnObj)
				}
				finally {
					//context.commit('addClient',payload)
				}

			})
		},
		async joinPool(context,payload){
			try {
				let poolDoc = await projectFirestore.collection('pools').doc(payload.poolID)
				let cloudFunc = []
				
				payload.players.forEach((player,index) => {
					poolDoc.update({
						players: field_value.arrayUnion(player)
					})

					cloudFunc[index] = axios({
						method: 'post',
						url: 'https://us-central1-squares-11.cloudfunctions.net/sendInvite ',
						data: {
							emailAddr: player,
							cd: 'f8c0bcded0b205073dab17ff23c96948',
							from: payload.from
						}

					}).then(function (response) {
						return response
					})
					.catch(function (error) {
						alert(error)
					});

				}) // forEach

				return Promise.all(cloudFunc).then(() => {
					return 'completed'
				})
			}
			catch(err){
				alert(err)
			}
		},
		async loadPools(context,payload){
			try {
				const unsubscribe = await projectFirestore.collection('pools')
				.where("players","array-contains",payload)
				.onSnapshot(snap => {
					let docs = snap.docs.map(doc => {
						return { ...doc.data(), id: doc.id }
					})
					context.commit('setPools',docs)
					context.commit('addPoolsSnap',unsubscribe)
				})
			}
			catch(err){
				alert(err)
			}
		},
		async loadBoxes(context,payload){
			try {
				const unsubscribe = await projectFirestore.collection('pools')
				.doc(payload)
				.collection('boxes')
				.where("poolID","==",payload)
				.onSnapshot(snap => {
					let docs = snap.docs.map(doc => {
						return { ...doc.data(), id: doc.id }
					})
					context.commit('setBoxes',docs)
					context.commit('addBoxesSnap',unsubscribe)
				})
			}
			catch(err){
				alert(err)
			}
		},
		async saveBox(context,payload){
			payload.dateCreated = timestamp()

			try {
				await projectFirestore.collection('pools')
				.doc(payload.poolID)
				.collection('boxes')
				.add(payload).then(() =>{
					return true
				})
			}
			catch(err){
				alert(err)
			}
		},
		async lockPool(context,payload){

			let theNumbers = [0,1,2,3,4,5,6,7,8,9]

			let shuffled_away = theNumbers
				.map(value => ({ value, sort: Math.random() }))
				.sort((a, b) => a.sort - b.sort)
				.map(({ value }) => value)

			let shuffled_home = theNumbers
				.map(value => ({ value, sort: Math.random() }))
				.sort((a, b) => a.sort - b.sort)
				.map(({ value }) => value)


			try {
				await projectFirestore.collection('pools')
				.doc(payload)
				.update({
					locked: true,
					teamAway: shuffled_away,
					teamHome: shuffled_home
				})
			}
			catch(err){
				alert(err)
			}
		},
		async saveGame(context,payload){
			try {
				await projectFirestore.collection('games')
				.doc(payload.id)
				.update({
					"1st": payload['1st'],
					"2nd": payload['2nd'],
					"3rd": payload['3rd'],
					"4th": payload['4th'],
					"active":  payload['active'],
					"teams": payload['teams'],
					"colors": payload['colors']
				})
			}
			catch(err){
				alert(err)
			}
		},
		async updateUsr(context,payload){


			await projectFirestore.collection("users")
			.where("uid", "==", payload.uid)
			.get()
			.then(querySnapshot => {
				if (!querySnapshot.empty) {
					const userDoc = querySnapshot.docs[0];
					userDoc.ref.update({ 
						nickname: payload.nickname 
					})
					.then(() => {
						console.log("Nickname updated");
					})
					.catch(error => {
						console.error("Error updating: ", error);
					});
				} else {
					console.log("No user found");
				}
			})
			.catch(error => {
				console.error("Error querying users: ", error);
			});

			await projectFirestore.collection("pools")
			.get()
			.then(querySnapshot => {
				querySnapshot.forEach(doc => {
					const pool = doc.data();
					if (pool.players.includes(payload.email)) {
						const boxesRef = projectFirestore.collection("pools")
						.doc(doc.id)
						.collection("boxes");
			
						boxesRef.where("usrID", "==", payload.uid)
						.get()
						.then(subQuerySnapshot => {
							subQuerySnapshot.forEach(subDoc => {
								boxesRef.doc(subDoc.id).update({ 
									nickname: payload.nickname
								})
								.then(() => console.log('nn updated'))
								.catch(error => console.error("Error updating nickname: ", error));
							});
						})
						.catch(error => {
							console.error("Error querying boxes: ", error);
						});
					}
				});
			})
			.catch(error => {
				console.error("Error querying pools: ", error);
			});
		}
	},
	modules: {
	}
})
