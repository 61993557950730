import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics';
//import 'firebase/compat/storage'

const firebaseConfig = {
	apiKey: "AIzaSyCWdjrbRyihHS2Coz-cxUfYlVhLoer9iVY",
	authDomain: "squares-11.firebaseapp.com",
	projectId: "squares-11",
	storageBucket: "squares-11.appspot.com",
	messagingSenderId: "857875745661",
	appId: "1:857875745661:web:b497737abc7179dd4a1a14",
	measurementId: "G-3W09WTLYZL"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
//const projectStorage = firebase.storage()
const field_value = firebase.firestore.FieldValue
const timestamp = field_value.serverTimestamp
const increment = field_value.increment(1)
const decrement = field_value.increment(-1)
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()


export { 
	projectAuth, 
	projectFirestore,
	//projectStorage,
	timestamp,
	increment,
	decrement,
	field_value,
	googleAuthProvider
}