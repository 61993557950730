<template>
	<router-view
		v-bind:allow = allow
		v-bind:version = version
	/>
</template>
<script>
import packageInfo from '../package'
import { projectAuth } from '@/firebase'

export default {
	data() {
		return {
			allow: projectAuth.currentUser ? 'granted': 'denied',
			version: packageInfo.version
		}
	},
	methods: {
		async loadGames() {
			await this.$store.dispatch('loadGames')
		},
		async loadAuth() {
			await this.$store.dispatch('loadAuth')
		}
	},
	async created(){
		if(projectAuth.currentUser != null){
			await this.loadAuth().then(() =>{
				if(projectAuth.currentUser.emailVerified === true){
					this.loadGames()
				}
			})
		}
	}
}
</script>
<style>
@import './assets/css/fontawesome.min.css';
@import './assets/css/duotone.min.css';
@import './assets/css/brands.min.css';

/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

html{color:#000;background:#FFF}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}ol,ul{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;*font-size:100%}legend{color:#000}#yui3-css-stamp.cssreset{display:none}

html{box-sizing:border-box}*,:after,:before{box-sizing:inherit}
html,body {font-size: 62.5%;}

@font-face {
	font-family: 'Varela Round';
	src: url(./assets/webfonts/Varela_Round/VarelaRound-Regular.ttf);
}

html,body {
	font-family: 'Varela Round', sans-serif;
}



:root {
	--brownDark: rgba(57,28,20,1);
}

.login-register #body,
footer {
	align-items: center;
}

.button-a,
.panel,
#steps,
.login-register form,
.notice {
	backdrop-filter: blur(3px);
}

.login-register {
	background: url(./assets/images/bg-field-football___74201342.jpg);
}

	@media screen and (max-width: 1023px) {
		.login-register {
			background: url(./assets/images/bg-field-football_m___74201342.jpg);
		}
	}

.login-register.logged-in-bg {
	background: url(./assets/images/bg-football-dark___232387250.jpg);
}

	@media screen and (max-width: 1023px) {
		.login-register.logged-in-bg {
			background: url(./assets/images/bg-football-dark_m___232387250.jpg);
		}
	}

form button {
	background-color: var(--brownDark);
}

body {
	background-color: #253c14;
}

form input {
	background-color: rgba(255,255,255,.96);
}

.button-a:hover {
	background-color: rgba(255,255,255,.93);
}

.button-a,
form,
.notice {
	background-color: rgba(255,255,255,.84);
}

header.main,
footer.main {
	background-color: rgba(0,0,0,.3);
}

#home,
#dashboard,
#logout {
	background-position: center;
}

.login-register {
	background-position: left center
}

#home,
#dashboard,
.login-register,
.login-register.logged-in-bg,
#logout {
	background-size: cover;
}

.button-a,
.panel,
form,
form input,
form textarea,
form button,
.notice,
#logout > div {
	border-radius: 6px;
}

.pool,
table,
#grid-wrapper {
	border-radius: 3px;
}

form button {
	border: 0 none;
}

.button-a,
form,
form input,
form textarea,
.notice {
	border: 1px solid var(--brownDark);
}

footer.main {
	bottom: 0;
}

.button-a,
form,
.notice,
.pool {
	box-shadow: 3px 3px 3px 1px rgba(0,0,0,.54);
}

#app,
form legend {
	color: var(--brownDark);
}

#home,
#dashboard,
#logout,
form button,
footer {
	color: #fff;
}

a {
	color: #013369;
}

.button-a {
	color: #000;
}

label,
button,
nav li {
	cursor: pointer;
}

#logout {
	display: grid;
}

form legend,
form label {
	display: block;
}

#home,
.login-register,
.login-register #body,
footer {
	display: flex;
}

.ext {
	display: inline-block;
}

#home,
.login-register {
	flex-direction: column;
}

#body {
	flex-grow: 1;
}

h1 {
	font-size: 5.4rem;
}

	@media only screen and (max-width: 639px) {
		h1 {
			font-size: 4.5rem;
		}
	}

	@media only screen and (max-width: 413px) {
		h1 {
			font-size: 3.72rem;
		}
	}

form legend {
	font-size: 2.1rem;
}

.button-a {
	font-size: 1.83rem;
}

	@media only screen and (max-width: 639px) {
		form legend {
			font-size: 1.74rem;
		}

		.button-a {
			font-size: 1.53rem;
		}
	}

	p,
	form,
	input,
	select,
	textarea,
	button {
		font-size: 1.74rem;
	}

	@media only screen and (max-width: 639px) {
		p,
		form,
		input,
		select,
		textarea,
		button {
			font-size: 1.65rem;
		}
	}

		@media screen and (max-width: 375px) {
			p,
			form,
			input,
			select,
			textarea,
			button {
				font-size: 1.62rem;
			}
		}

label span,
.add-lnks {
	font-size: 1.5rem;
}

strong,
.button-a,
form legend,
form button,
.notice { 
	font-weight: bold;
}

footer {
	gap: 15px;
}

.login-register #body {
	justify-content: center;
}

footer {
	left: 0;
}

p {
	line-height: 1.65;
}

h1 {
	line-height: 1;
}

#home #body {
	margin-top: 72px;
}

.login-register #body form {
	margin-top: 63px;
}

.add-lnks {
	margin-top: 6px;
}

p {
	margin-bottom: 1.65rem;
}

.notice p {
	margin-bottom: 0;
}

h1 {
	margin-bottom: 1.02rem;
}

.label-field {
	margin: 15px 0;
}

	@media screen and (max-width: 639px) {
		.label-field {
			margin: 12px 0;
		}
	}

		@media screen and (max-width: 413px) {
			.label-field {
				margin: 9px 0;
			}
		}


#app,
#home,
.login-register,
#dashboard,
#logout {
	min-height: 100vh;
}

button[disabled] {
	opacity: .72;
}

footer.main {
	margin-right: auto;
}

button span + i {
	margin-left: .3ch;
}

input:focus {
	outline: 2px solid var(--brownDark);
}

form,
.notice {
	padding: 21px;
}

	@media screen and (max-width: 479px) {
		form,
		.notice {
			padding: 15px;
		}
	}

header.main {
	padding: 18px;
}

footer.main {
	padding: 12px 18px;
}

form input,
form textarea,
form button {
	padding: 9px;
}

.button-a {
	padding: 12px 36px;
}

label {
	padding: 6px 0;
}

	@media screen and (max-width: 375px) {
		label {
			padding: 3px 0;
		}
	}

#home,
#logout  {
	place-items: center;
}

header {
	position: fixed;
}

form legend,
.notice {
	text-align: center;
}

.button-a {
	text-decoration: none;
}

form legend,
form button {
	text-transform: uppercase;
}

header.main {
	top: 0
}

button,
.button-a,
.pool-actions a,
.pool-actions a i {
	transition: all 333ms ease-in-out;
}

form,
.notice {
	width: 360px;
}

	@media screen and (max-width: 479px) {
		form,
		.notice {
			width: calc(100vw - 24px);
		}
	}

header,
footer:not(.main),
form legend,
form label,
form input,
form textarea,
form button,
footer.main {
	width: 100%;
}

header {
	z-index: 1;
}



.form-note {
	background-color: rgba(255,255,255,.33);
	border-radius: 3px;
	display: block;
	font-size: 1.29rem;
	margin-top: 0;
	opacity: .78;
	padding: 3px;
}



#sign-in-google {
	cursor: pointer;
	text-align: center;
}

.or {
	font-size: 1.8rem;
	font-weight: bold;
	text-align: center;
}
</style>
